<template>
  <div style="background:#e7e7e7;height:100%">
    <div class="has-text-centered">
      <figure class="" style="">
        <img :src="logoImagePath" style="width:25%;height:25%" alt="Brand" />
      </figure>
      </div>
    <div class="box2">
      <h3 class="title is-4" style="color: #000000; text-transform: none; font-family:'Open Sans';">CDaaS Results</h3>
      <p class="subtitle has-text-black"style="font-family:'Open Sans';">Please enter at least 3 search criteria to get results</p>
    
    <div class="box is-shadowless" :class="brandName">
      <CdaasLandingForm :referenceID="referenceID" />
    </div>
  </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CdaasLandingForm from '@/components/shared/CdaasLandingForm.vue';

export default {
  props: {
    referenceID: {
      type: String,
      default: null
    }
  },
  computed: {
    logoImagePath() {
      return '/assets/images/cdaas_logo.png';
    },
    ...mapState('layout', ['brandName'])
  },
  components: {
    CdaasLandingForm
  }
};
</script>

<style scoped>
.hds,
.iqvia,
.sjv {
  background: white;
}

.typhoon {
  background: transparent;
}

/* Add any other styles you need here */
.has-text-centered { /* Example: Style the centered content */
  text-align: center;
}

.box { /* Example: Style the box */
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  text-align: center;
  width: 90%;
}
.box2 { /* Example: Style the box */
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  text-align: center;
  background: #a3aaad;
  width: 50%
}
</style>