<template>
  <div>
    <form @submit.prevent="submit">
      <b-field>
        <label class="label">Search/Order ID</label><TdInput class="inputfield" type="id":hasIconLeft="false" v-model.trim="credentials.id"></TdInput>
      </b-field>
      <b-field>
        <label class="label">First Name</label><TdInput class="inputfield" type="text":hasIconLeft="false" v-model.trim="credentials.first"></TdInput>
      </b-field>
      <b-field>
        <label class="label">Last Name</label><TdInput class="inputfield" type="text":hasIconLeft="false" v-model.trim="credentials.last"></TdInput>
      </b-field>
      <b-field>
        <label class="label">Date of Birth</label><TdInput class="inputfield" type="date":hasIconLeft="false" v-model.trim="credentials.dob"></TdInput>
      </b-field>
      <b-field v-if="showValidationMessage" type="is-danger">
        <b-message title="Validation Error" type="is-danger" aria-close-label="Close message">
          Please fill in at least 3 out of the 4 fields.
        </b-message>
      </b-field>
      <TdSubmit label="Get Results" class="red-button" />
    </form>
    <div v-if="showWaitMessage" class="wait-message">
      Please wait while we fetch your file...
    </div>
    <div v-if="showUrlErrorMessage" class="url-error-message">
      Error: Order number is missing from the URL.
    </div>
    <div v-if="errorMessage" class="api-error-message">
      API Error: {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import FileSaver from 'file-saver';
import apiClient from '@/services/shared/AxiosService.js';

export default {
  props: {
    referenceID: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      credentials: {
        id: '',
        first: '',
        last: '',
        dob: ''
      },
      showValidationMessage: false,
      showWaitMessage: false,
      showUrlErrorMessage: false,
      errorMessage: null
    };
  },
  computed: {
  ...mapGetters('auth_user', ['errorMessage']),
  ...mapState('layout', ['brandName'])
  },
  methods: {
    submit() {
      const num_creds =
        (this.credentials.id.length > 0) +
        (this.credentials.first.length > 0) +
        (this.credentials.last.length > 0) +
        (this.credentials.dob.length > 0);

      if (num_creds >= 3) {
        this.showValidationMessage = false;

        if (this.referenceID) {
          this.showWaitMessage = true;
          this.showUrlErrorMessage = false;

          // Map front end field names to backend field names
          const requestData = {
            orderId: this.credentials.id,
            firstName: this.credentials.first,
            lastName: this.credentials.last,
            dob: this.credentials.dob
          };

          console.log('Sending data to backend:', requestData);

          apiClient({
            method: 'post',
            url: `/cdaas/${this.referenceID}`,
            data: requestData,
            responseType: 'blob'
          })
        .then(response => {
              const contentType = response.headers['content-type'];
              const blob = new Blob([response.data], { type: contentType });

              let filename = this.referenceID + '.zip';
              const contentDisposition = response.headers['content-disposition'];

              if (contentDisposition) {
                const filenameMatch = /filename="([^"]*)"/i.exec(contentDisposition);
                if (filenameMatch && filenameMatch.length > 1) {
                  filename = filenameMatch; // Extract the filename from the match
                }
              }

              FileSaver.saveAs(blob, filename);
              this.errorMessage = null;
            })
        .catch(error => {
              console.error('API Error:', error);
              this.showUrlErrorMessage = false;

              let message = 'An error occurred.';
              if (error.response) {
                if (error.response.status === 400) {
                  message = 'Bad request: Please check your input.';
                } else if (error.response.status === 500) {
                  message = 'Server error: Please try again later.';
                } else {
                  message = error.response.data.message || message;
                }
              } else if (error.request) {
                message = 'No response from the server.';
              } else {
                message = error.message || message;
              }

              this.errorMessage = message;
            })
        .finally(() => {
            this.showWaitMessage = false;
          });
        } else {
          this.showUrlErrorMessage = true;
        }
      } else {
        this.showValidationMessage = true;
      }
    }
  }
};
</script>

<style scoped>.wait-message {
  margin-top: 10px;
  font-style: italic;
}.url-error-message {
  margin-top: 10px;
  color: #d9534f;
}.api-error-message {
  margin-top: 10px;
  color: orange;
}.red-button {
  background-color: #9e1b32 !important;
  color: #e7e7e7e7!important;
}.label{
  font-family:'Open Sans';
  width:25%
}.inputfield{
  align-items: right!important;
  width:100%!important;
}
</style>