import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/bryce'
import Meta from 'vue-meta'
import NProgress from 'nprogress'

import BryceLayout from '@/layouts/BryceLayout.vue'
import LoginLayout from '@/layouts/shared/LoginLayout.vue'
import ResetLayout from '@/layouts/shared/ResetLayout.vue'
import ForgotLayout from '@/layouts/shared/ForgotLayout.vue'

// import ActionResults from '@/views/ActionResults.vue'
import Batches from '@/views/bryce/Batches.vue'
import BatchView from '@/views/bryce/BatchView.vue'
import Customers from '@/views/bryce/Customers.vue'
import CustomerView from '@/views/bryce/CustomerView.vue'
import Dashboard from '@/views/bryce/Dashboard.vue'
import HealthCheck from '@/views/shared/HealthCheck.vue'
import Groups from '@/views/bryce/Groups.vue'
import GroupView from '@/views/bryce/GroupView.vue'
import MonitoringAlerts from '@/views/bryce/MonitoringAlerts.vue'
import MonitoringBatches from '@/views/bryce/MonitoringBatches.vue'
import MonitoringForm from '@/views/bryce/MonitoringForm.vue'
import MonitoringSearch from '@/views/bryce/MonitoringSearch.vue'
import MonitoringReports from '@/views/bryce/MonitoringReports.vue'
import OneTimeBatches from '@/views/bryce/OneTimeBatches.vue'
import OneTimeForm from '@/views/bryce/OneTimeForm.vue'
import OneTimeHistory from '@/views/bryce/OneTimeHistory.vue'
import ProviderForm from '@/views/bryce/ProviderForm.vue'
import ProviderResults from '@/views/bryce/ProviderResults.vue'
import ProviderView from '@/views/bryce/ProviderView.vue'
import Reports from '@/views/bryce/Reports.vue'
import NotFound from '@/views/NotFound.vue'
import UserConfirm from '@/views/shared/UserConfirm.vue'
import UserLogin from '@/views/shared/UserLogin.vue'
import CdaasLanding from '@/views/shared/CdaasLanding.vue'
import UserLogout from '@/views/shared/UserLogout.vue'
import UserRecover from '@/views/shared/UserRecover.vue'
import SettingsView from '@/views/bryce/SettingsView.vue'

Vue.use(Meta)
Vue.use(VueRouter)

const routes = [
  {
    path: '/cdaas/:referenceID', // Add a dynamic parameter to capture the order ID
    name: 'cdaas',
    component: CdaasLanding,
    meta: {
      requiresAuth: false,
      guest: true
    },
    props: true // Pass the reference ID as a prop to the component
  },
  {
    path: '/user',
    component: LoginLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: UserLogin,
        meta: {
          requiresAuth: false,
          guest: true
        }
      },
      {
        path: 'logout',
        name: 'logout',
        component: UserLogout,
        meta: {
          requiresAuth: false,
          guest: true
        }
      }
    ]
  },
  {
    path: '/user',
    component: ForgotLayout,
    children: [
      {
        path: 'recover',
        name: 'recover',
        component: UserRecover,
        meta: {
          requiresAuth: false,
          guest: true
        }
      }
    ]
  },
  {
    path: '/confirm_password_reset_link',
    component: ResetLayout,
    children: [
      {
        path: '',
        name: 'confirm',
        component: UserConfirm,
        meta: {
          requiresAuth: false,
          guest: true
        }
      }
    ]
  },
  {
    path: '/health_check',
    component: HealthCheck,
    meta: {
      requiresAuth: false,
      guest: true
    }
  },
  {
    path: '/',
    component: BryceLayout,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'proxy-login',
        name: 'proxy-login',
        meta: {
          requiresAuth: true
        },
        beforeEnter(routeTo, routeFrom, next) {
          store
            .dispatch('auth_user/proxyLogin', { customer_user_id: routeTo.params.id })
            .then(() => {
              store.dispatch('batch/clearList')
              store.dispatch('provider_result/clearResults')
              store.dispatch('action_result/fetchAlertsCount')
              next({ name: 'dashboard' })
            })
            .catch(error => {
              const notification = { type: 'error', message: 'There was an error: ' + error.message + '. Calling: proxyLogin' }
              store.dispatch('notification/add', notification, { root: true })
              next({ name: 'dashboard' })
            })
        }
      },
      {
        path: 'proxy-logout',
        name: 'proxy-logout',
        meta: {
          requiresAuth: true
        },
        beforeEnter(routeTo, routeFrom, next) {
          store
            .dispatch('auth_user/proxyLogout', {})
            .then(() => {
              store.dispatch('batch/clearList')
              store.dispatch('provider_result/clearResults')
              store.dispatch('action_result/fetchAlertsCount')
              next({ name: 'dashboard' })
            })
            .catch(error => {
              const notification = { type: 'error', message: 'There was an error: ' + error.message + '. Calling: proxyLogout' }
              store.dispatch('notification/add', notification, { root: true })
              next({ name: 'dashboard' })
            })
        }
      },
      {
        path: 'batches',
        name: 'batches',
        component: Batches,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'batch-view',
        name: 'batch-view',
        component: BatchView,
        meta: {
          requiresAuth: true
          // breadCrumbList: [{ name: 'Batches', link: 'batches' }, { name: 'View Batch' }]
        },
        props(route) {
          return {
            id: route.query.id,
            monitoring: String(route.query.monitoring).toLowerCase() === 'true'
          }
        }
      },
      {
        path: 'customer/add',
        name: 'customer-add',
        component: Customers,
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'customer/search',
        name: 'customer-search',
        component: Customers,
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'customer/view/:id',
        name: 'customer-view',
        component: CustomerView,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'groups',
        name: 'groups',
        component: Groups,
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'group-view',
        name: 'group-view',
        component: GroupView,
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
        props(route) {
          return {
            id: route.query.id,
            monitoring: String(route.query.monitoring).toLowerCase() === 'true'
          }
        }
      },
      {
        path: 'monitoring-alerts',
        name: 'monitoring-alerts',
        component: MonitoringAlerts,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'monitoring-batches',
        name: 'monitoring-batches',
        component: MonitoringBatches,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'monitoring-form',
        name: 'monitoring-form',
        component: MonitoringForm,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'monitoring-search',
        name: 'monitoring-search',
        component: MonitoringSearch,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'monitoring-reports',
        name: 'monitoring-reports',
        component: MonitoringReports,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'onetime-batches',
        name: 'onetime-batches',
        component: OneTimeBatches,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'onetime-form',
        name: 'onetime-form',
        component: OneTimeForm,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'onetime-history',
        name: 'onetime-history',
        component: OneTimeHistory,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'provider-new',
        name: 'provider-new',
        component: ProviderForm,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'provider-edit',
        name: 'provider-edit',
        component: ProviderForm,
        props(route) {
          if (route.query) {
            route.query.id = +route.query.id
          }
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'provider-results',
        name: 'provider-results',
        component: ProviderResults,
        props(route) {
          return route.query || {}
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'provider-view',
        name: 'provider-view',
        component: ProviderView,
        props(route) {
          return {
            id: route.query.id,
            isMonitoring: String(route.query.monitoring).toLowerCase() === 'true'
          }
        },
        meta: {
          requiresAuth: true
          // breadCrumbList: [{ name: 'Providers', link: 'provider-results' }, { name: 'Provider View' }]
        }
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'settings',
        name: 'settings',
        component: SettingsView,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    props: true
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
]

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

// https://scotch.io/tutorials/vue-authentication-and-route-handling-using-vue-router
router.beforeEach((to, from, next) => {
  NProgress.start()
  let isAuthenticated = store.getters['auth_user/isAuthenticated']
  let hasAdmin = store.getters['auth_user/hasAdmin']
  store.dispatch('layout/setMenu', { menu: to.name }, { root: true })
  // console.log('to:', to)
  // console.log('isAuthenticated:', isAuthenticated, '; hasAdmin', hasAdmin, '; to.path:', to.fullPath, '; to.query:', to.query)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: 'login', params: { nextUrl: to.fullPath } })
    } else {
      if (to.matched.some(record => record.meta.requiresAdmin) && !hasAdmin) {
        next({ name: 'dashboard' })
      } else if (from.params.nextUrl) {
        // I couldn't get query.redirect to work, something was hammering it - setting it to {}. So we are using params.nextUrl instead.
        // To avoid infinite loop we have to clear from.params.nextUrl
        let p = from.params.nextUrl
        from.params.nextUrl = null
        next(p)
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
